<template>
  <div id="ProgrammeDetail">
    <div class="header">
      <!-- <div class="top">
      <img :src="activityDetail.thumbimgurl ? Global.changeImg(activityDetail.thumbimgurl) :'../assets/image/home/index.png'" />
      </div>
      <div class="list">
        <div>
          <span>{{activityDetail.title}}</span>
        </div>
        <div>
          <span>报名截止：{{activityDetail.activity_time_end.split(' ')[0]}}</span>
        </div>
        <div>
          <span>{{activityDetail.activity_time_start.split(' ')[0]}}（{{getWeek($moment(activityDetail.activity_time_start).day())}}）</span>
          <span><van-icon name="location" style="margin:0.02rem 0.05rem 0 0.02rem;" />{{activityDetail.activity_location}}</span>
        </div>
      </div>
    </div>

    <div class="personalInfo">
      <div style="padding-right:0" v-if="activity_guest.length">
        <p  class="second">活动嘉宾</p>
        <div class="familiar">
          <div v-for="(i,item) in activity_guest" :key="item">
            <img :src="i.user.headimgurl ? Global.changeImg(i.user.headimgurl) :'../assets/image/home/image1.png'" />
            <p>{{i.member_info.name}}</p>
            <p>{{i.member_info.company}}</p>
            <p>{{i.member_info.job}}</p>
          </div>
        </div>
      </div> -->
      <div style="margin-bottom:0;padding-bottom:0.15rem" v-if="activityDetail.content">
        <!-- <p  class="second">活动内容</p> -->
        <div style="color:#F6F7FD" v-html="activityDetail.content"></div>
        <!-- <img class="contentact" src="../../assets/image/home/index.png"/> -->
      </div>

    </div>

    <!-- <div class="fixedBottom" @click="toTip" v-if="activityDetail.activity_apply_list.length && activityDetail.activity_apply_list[0].status ==2 " >
      <img src="../../assets/image/findOut/time.png" />
      报名详情
    </div>
    <div class="fixedBottom" @click="toZF" v-else-if="activityDetail.activity_apply_list.length && activityDetail.activity_apply_list[0].status ==1 " >
      <img src="../../assets/image/findOut/time.png" />
      待支付
    </div>
    <div class="inline-buttons" v-else>
      <div class="inline-button " @click="toForms" style="flex: 1.2;margin-left: .2rem;margin-right: .1rem">
        <img src="../../assets/image/findOut/time.png" />
        立即报名
      </div>

      <div class="inline-button " @click="show = true" style="flex: 2;margin-left: .1rem;margin-right: .2rem">
        <img src="../../assets/image/findOut/wechat.png" />
        加客服免费报名
      </div>
    </div>

    <van-popup v-model="show" style="background:none">
      <img class="main" src="img/kf2.png" />
      <div class="close">
        <span @click="show = false">
          <van-icon color="#fff" name="cross" style="font-size:0.2rem" />
        </span>
      </div>
    </van-popup> -->
  </div>
</template>
<script>
export default {
  name: 'ProgrammeDetail',
  data(){
    return{
      liveNow:1,
      show:false,
      activityId:'',
      activityDetail:'',
      activity_guest:'',//活动嘉宾
    }
  },
  mounted(){
    this.activityId = this.$route.query.activityId
    this.axios({
      method: 'GET',
      url: '/activity/getActivityDetail?activityId='+this.activityId,
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.activityDetail = res.data.data
        this.activity_guest = res.data.data.activity_guest

      }
    })
  },
  methods:{
    // 查看报名凭证
    toTip(){
      this.$router.push({
         path: '/SignUpEnd?activityId='+this.activityDetail.id+'&type=c',
      })
    },
    toZF(){
      this.$router.push({
         path: '/SignUpEnd?activityId='+this.activityDetail.id+'&payId='+this.activityDetail.pay_log.id+'&type=c',
      })
    },
    // 跳转线下活动报名
    toForms(){
      this.$router.push({
        path: '/Forms?activityId='+this.activityId,
      })
    },
    getWeek(a){
      switch(a){
        case 1:
        return '周一';
        case 2:
        return '周二';
        case 3:
        return '周三';
        case 4:
        return '周四';
        case 5:
        return '周五';
        case 6:
        return '周六';
        case 7:
        return '周日';
      }
    }
  }
}
</script>
<style lang="less" scoped>
#ProgrammeDetail{
  .main{
    width: 2.63rem;
  }
  .close{
    text-align: center;
    >span{
      width: 0.35rem;
      height: 0.35rem;
      background: #6e6f72;
      border-radius: 50%;
      margin-top: 0.2rem;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 0.2rem;
    }
  }
  .inline-buttons {
    left: 0;
    right: 0;
    position: fixed;
    bottom: 0.56rem;
    height: 0.42rem;
    display: flex;
    justify-content: space-around;
  }
  .inline-button {
    height: 0.42rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F6F7FD;
    background: #3890FF;
    border-radius: 0.21rem;
    >img{
      width: 0.16rem;
      margin-right: 0.06rem;
    }
  }
  .fixedBottom{
    left: 0;
    right: 0;
    margin: auto;
    position: fixed;
    bottom: 0.56rem;
    width: 2.09rem;
    height: 0.42rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F6F7FD;
    background: #3890FF;
    border-radius: 0.21rem;
    >img{
      width: 0.16rem;
      margin-right: 0.06rem;
    }
  }
  .gray{
    background: #9091A3;
  }
  .contentact{
    width: 100%;
    margin-top: 0.15rem;
  }
  .list{
    >div:first-child{
      display: flex;
      align-items: center;
      justify-content: space-between;
      >span:first-child{
        color: #F6F7FD;
        font-size: 0.15rem;
      }
    }
    >div:last-child,>div:nth-child(2){
      color: #727387;
      font-size: 0.12rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0.06rem 0 0.08rem;
    }
  }
  
  .familiar{
    overflow-x: scroll;
    padding-top: 0.15rem;
    margin-bottom: 0.1rem;
    white-space: nowrap;
    >div{
      width: 1.29rem;
      border-radius: 0.05rem;
      margin-right: 0.15rem;
      text-align: center;
      display: inline-block;
      background:#3D404F;
      >img{
        display: inline-block;
        width: 0.54rem;
        border-radius: 50%;
        margin: 0.1rem 0 0.09rem;
      }
      >p:nth-child(2){
        color: #FFFFFF;
        font-size: 0.14rem;
      }
      >p:nth-child(3){
        color: #9091A3;
        font-size: 0.12rem;
        margin: 0.03rem 0 0.02rem;
      }
      >p:nth-child(4){
        color: #9091A3;
        font-size: 0.12rem;
        margin: 0.03rem 0 0.1rem;
      }
    }
  }
  .personalInfo{
    >div{
      padding: 0.15rem 0.15rem 0.1rem;
      margin-bottom: 0.11rem;
      background: #23252E;
      .third{
        color: #989AB1;
        font-size: 0.13rem;
        line-height: 0.17rem;
        padding: 0.15rem 0 0.1rem;
      }
      .second{
        color: #F6F7FD;
        font-size: 0.15rem;
      }
    }
  }
  
  .header{
    min-height: 100vh;
    background: #23252E;
    // margin-bottom: 0.11rem;
    // padding: 0 0.15rem 0.15rem;
    overflow: hidden;
    >.top{
      position: relative;
      >img{
        width: 100%;
        margin: 0.15rem 0;
      }
    }
  }
    
}
</style>